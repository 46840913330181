// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
  var window_height = $window.height();
  var window_top_position = $window.scrollTop();
  var window_bottom_position = (window_top_position + window_height);

  $.each($animation_elements, function() {
    var $element = $(this);
    var element_height = $element.outerHeight();
    var element_top_position = $element.offset().top;
    var element_bottom_position = (element_top_position + element_height);

    //check to see if this current container is within viewport
    if ((element_bottom_position >= window_top_position) &&
      (element_top_position <= window_bottom_position)) {
      $element.addClass('active');
    }
    //else {
      //$element.removeClass('active');
    //}
  });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

// IMAGE REPLACE - img src to bg css
function replaceSrc() {

     $('.js-replace-img').each(function() {

       var src = $(this).find("img").attr("src");
       $(this).css("background-image","url("+src+")");
     });
    };

    jQuery(function() {
     if($(".js-replace-img")) {
       replaceSrc();
     }
    });



(function(){

  var s = {

    menuState: function() {

      $(this).toggleClass("cross");
      $(".main-nav-wrapper").toggleClass("open");

    },

    headerHeight: function() {

      if($(window).scrollTop() > 0) {

        return $('.main-header').height();
      } else {

        return $('.main-header').height() - 24;
      }
    },

    headerPosition: function() {

      var top = $(window).scrollTop();

      if(top > 72) {

        $(".main-header").addClass("min");
        $(".sub-nav").addClass("min");
      } else {

        $(".main-header").removeClass("min");
        $(".sub-nav").removeClass("min");
      }
    },

    events: function() {
      $(window).scroll(this.headerPosition);
      $("#btnMobile").on("click",this.menuState);
    },

    init: function() {

      this.headerPosition();
      this.events();
    }

  }

  s.init();

})();

// RESPONSIVE MENU
jQuery('#sub-nav-open').click(function() {
  jQuery(this).toggleClass('active');
  jQuery('.menu-content').toggleClass('open');
});

// TABS
(function($) {
"use strict";
$(
  document
).on("show.bs.tab", '.nav-tabs-responsive [data-toggle="tab"]', function(e) {
  var $target = $(e.target);
  var $tabs = $target.closest(".nav-tabs-responsive");
  var $current = $target.closest("li");
  var $parent = $current.closest("li.dropdown");
  $current = $parent.length > 0 ? $parent : $current;
  var $next = $current.next();
  var $prev = $current.prev();
  var updateDropdownMenu = function($el, position) {
    $el
      .find(".dropdown-menu")
      .removeClass("pull-xs-left pull-xs-center pull-xs-right")
      .addClass("pull-xs-" + position);
  };

  $tabs.find(">li").removeClass("next prev");
  $prev.addClass("prev");
  $next.addClass("next");

  updateDropdownMenu($prev, "left");
  updateDropdownMenu($current, "center");
  updateDropdownMenu($next, "right");
});
})(jQuery);
